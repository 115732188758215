import {Button, Modal, Form, Input, Space} from "antd";
import {useContext} from "react";
import {UserContext} from "../../../providers/UserProvider";
import {useAuth0} from "@auth0/auth0-react";
import {FallOutlined} from "@ant-design/icons";


function CreateOrg() {
    const {user} = useAuth0();
    const {initOrganisation} = useContext(UserContext);

    return (
        <>
            <p>You're almost ready to start using FirstMate.</p>
            <Form
                name="org-setup"
                layout="vertical"
                onFinish={initOrganisation}
                labelCol={{span: 8}}
                wrapperCol={{span: 16}}
                style={{maxWidth: 800, marginTop: 20}}
                autoComplete="off"
                initialValues={{
                    // eslint-disable-next-line
                    ["email"]: user.email,
                    // eslint-disable-next-line
                    ["name"]: user.nickname

                }}
            >
                <Form.Item
                    label="Name"
                    name="name"
                    rules={[{required: true, message: 'Please check you filled in your name'}]}>
                    <Input placeholder="John Doe" style={{width: 300}}/>
                </Form.Item>
                <Form.Item
                    label="Email"
                    name="email"
                    rules={[{required: true, message: 'Please double check your email'}]}>
                    <Input placeholder="john.doe@yourcompany.com" style={{width: 300}}/>
                </Form.Item>
                <Form.Item
                    label="Organisation"
                    name="org"
                    rules={[{required: true, message: 'Please provide an organisation name'}]}>
                    <Input placeholder="your-org-name" style={{width: 300}}/>
                </Form.Item>

            </Form></>
    )


}

export default function OnboardingFlow() {
    return (<>
        <Modal centered className={"with-background"} open={true}
               onCancel={() => {
               }} width={700}
               footer={[<Button type="primary" form="org-setup" key="submit" htmlType="submit">
                   Next Step
               </Button>]}
        >
            <div className="modal-background"></div>
            <Space style={{position: "absolute", top: 85, right: 30}}></Space>
            <div className="modal-icon"><FallOutlined/></div>
            <h2 style={{float: "left", marginRight: 20, marginTop: -5}}>Account setup</h2>
            <br/>
            <br/>
            <CreateOrg/>
        </Modal>
    </>)
}


