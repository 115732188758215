import * as React from "react";
import {Layout, ConfigProvider} from 'antd';
import {MenuSider} from "./";
import {useState, useContext} from "react";
import {Route, Routes, useParams} from "react-router-dom";
import {UserContext} from "../../providers/UserProvider";
import BlockingModal from "./elements/PermissionModal";
import OnboardingFlow from "../onboarding/modals/OnboardingModal"
import Loader from "./elements/Loader";
import Repos from "./screens/Repos";
import Settings from "../settings/Settings";
import SubscriptionPlan from "./screens/SubscriptionPlan";
import GuideModal from "../onboarding/modals/GuideModal";


const {Content} = Layout;

export default function Dashboard() {
    const [collapsed, setCollapsed] = useState(false);
    const [openGuide, setOpenGuide] = useState(true);
    const {permission, errorMessage, organisation} = useContext(UserContext);
    const {view, screen} = useParams();

    const renderModals = () => {
        if (permission === "B002")
            return (
                <BlockingModal title="You don't have the right permissions" content={errorMessage}/>
            )
        if (["B001", "B008"].includes(permission))
            return (<OnboardingFlow/>)
    }

    if (!organisation && !permission) {
        return (<Layout hasSider>
                <MenuSider setSideBar={setCollapsed} view={view}/>
                <Content style={{
                    transition: "margin 300ms",
                    minHeight: "100vh",
                    padding: "32px 55px",
                    marginLeft: collapsed ? 80 : 250
                }}>
                    <div className={"loading-icon"}>
                        <Loader size="80" type="2"/>
                    </div>
                </Content>
            </Layout>
        )
    }

    return (
        <ConfigProvider
            theme={{
                token: {
                    fontFamily: "Inter"
                }
            }}>
            {renderModals()}
            <Layout hasSider>
                <MenuSider setSideBar={setCollapsed} view={view} screen={screen}/>
                <Content style={{
                    transition: "margin 300ms",
                    minHeight: "100vh",
                    padding: "32px 55px",
                    marginLeft: collapsed ? 80 : 200
                }}>
                    {organisation?.subscription?.users?.length === 0 && organisation?.accessRights?.gitProviders.length > 0 &&
                        <GuideModal open={openGuide} onCancel={() => setOpenGuide(false)}/>}
                    {permission === "B002" || permission === "B001" ? <Repos/> :
                        <Routes>
                            <Route path="subscription" element={<SubscriptionPlan/>}/>
                            <Route path="repos" element={<Repos/>}/>
                            <Route path="settings/*" element={<Settings/>}/>
                        </Routes>}

                </Content>
            </Layout>
        </ConfigProvider>

    );
}
