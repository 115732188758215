import {Layout, Menu} from 'antd';
import Icon from "@ant-design/icons";
import React, {useContext, useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {UserContext} from "../../../providers/UserProvider";
import {BranchesOutlined, ThunderboltOutlined, LogoutOutlined, ReadOutlined} from "@ant-design/icons"
import {useAuth0} from "@auth0/auth0-react";

const {Sider} = Layout;


export default function MenuSider({setSideBar, view, screen}) {
    const location = useLocation()
    const { user } = useAuth0();
    console.log(user);

    const [collapsed, setCollapsed] = useState(false);
    const {logout} = useContext(UserContext);
    const [current, setCurrent] = useState(location.pathname);

    useEffect(() => {
        if (location) {
            if (current !== location.pathname) {
                setCurrent(location.pathname);
            }
        }
    }, [location, current]);


    const cloudItems = [
        {
            key: '/dashboard/repos',
            label: <Link to="/dashboard/repos">Repositories</Link>,
            icon: <BranchesOutlined/>,
        },
        {
            key: '/dashboard/subscription',
            label: <Link to="/dashboard/subscription">Subscription</Link>,
            icon: <ThunderboltOutlined/>,
        },
        {
            key: "/dashboard/settings",
            label: <Link to="/dashboard/settings">Settings</Link>,
            icon: <Icon component={() => (<img className="icon-img" alt="" src="/icons/settings.png"/>)}/>
        },
        {
            key: null,
            label: <Link to="https://docs.firstmate.io" target="_blank" rel="noopener noreferrer" >Documentation</Link>,
            icon: <ReadOutlined />
        }
    ]

    const secondaryItems = [{
        key: 0,
        label: <Link to="/" onClick={logout}>Logout</Link>,
        icon: <LogoutOutlined />
    },
       ]


    return (
        <Sider style={{overflow: 'auto', height: '100vh', position: 'fixed', left: 0, top: 0, bottom: 0}} width={220}
               collapsible collapsed={collapsed} onCollapse={(value) => {
            setCollapsed(value);
            setSideBar(value);
        }}>
            {!collapsed &&
                <div style={{height: 80, position: "absolute", top: 0, width: "100%", alignContent: "center"}}>
                    <img alt="logo" src="/logo.png" style={{width: 200}}/>
                </div>}

            <div className="gradient-1" style={{height: "100%", paddingTop: (collapsed ? 20 : 90)}}>
                <Menu theme="dark" mode="inline" selectedKeys={[current]} items={cloudItems}/>
            </div>
            {!collapsed &&
            <div className="bordered-side">
                <img alt="logo" className={"profile-pic"} src={user && user.picture}/>
                <p className={"username"}>
                    {user && user.nickname}</p>
                <Menu selectable={false} style={{paddingTop: 10}} theme="dark" mode="inline"
                      items={secondaryItems} selectedKeys={[current]}/>


            </div>}

        </Sider>


    );
}
