import {Modal, Steps, Space, Checkbox} from "antd";
import {CheckOutlined} from "@ant-design/icons";



export default function GuideModal({open, onCancel}) {
    return (<>
        <Modal centered open={open} onCancel={onCancel} className={"with-background"} footer={[]}>
            <div className="modal-background"></div>
            <Space style={{position: "absolute", top: 85, right: 30}}></Space>
            <div className="modal-icon"><CheckOutlined/></div>
            <h2 style={{float: "left", marginRight: 20, marginTop: -5}}>You are ready!</h2>
            <div style={{ marginTop: 70}}>
            <Steps
                style={{ marginTop: 30}}
                direction="vertical"
                current={1}
                items={[
                    {
                        title: 'Link your code repositories',
                        description: <> <Checkbox checked={true} disabled={true}/> FirstMate is connected to your git provider.</>,
                    },
                    {
                        title: 'Use FirstMate by creating pull requests.',
                        description: <><Checkbox checked={true}/> PRs are automatically reviewed.<br/><Checkbox checked={true}/> If a pipeline crashes, FirstMate gives feedback.</>,
                    },
                    {
                        title: 'Add custom guidelines',
                        description: <>Most companies add custom guidelines in markdown by following <a href="https://docs.firstmate.io/guidelines/repo" target="_blank" rel="noopener noreferrer">this guide.</a></>,
                    },
                ]}
            />
            </div>
        </Modal>
    </>)
}


